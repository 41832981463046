import React from "react";
import IMG from 'gatsby-image';
import {Link} from 'gatsby';

const Footer = ({image}) => {

    return (
        <footer className="bg-white">
            <div className="container mx-auto  px-8">

                <div className="w-full flex flex-col md:flex-row py-6">

                    <div className="flex-1 mb-6">


                        <a href="https://www.nerdy-bear.com">
                            <IMG className="w-1/2" fluid={image} alt="logo de Kristen Garnier / Nerdy bear"/>
                        </a>
                    </div>

                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Legal</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link to="/cgu" className="no-underline hover:underline text-gray-800 hover:text-orange-500">CGU</Link>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <Link to="/vie-privee" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Vie privée</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Social</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="https://www.linkedin.com/in/kristen-garnier-b2b191a8/" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Linkedin</a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="https://twitter.com/GarnierKristen" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Twitter</a>
                            </li>
                        </ul>
                    </div>
                    <div className="flex-1">
                        <p className="uppercase text-gray-500 md:mb-6">Company</p>
                        <ul className="list-reset mb-6">
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="https://nerdy-bear.com" className="no-underline hover:underline text-gray-800 hover:text-orange-500">À propos de Nerdy bear</a>
                            </li>
                            <li className="mt-2 inline-block mr-2 md:block md:mr-0">
                                <a href="https://nerdy-bear.com" className="no-underline hover:underline text-gray-800 hover:text-orange-500">Contact</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;