import React from "react";
import {Link} from 'gatsby';
import classnames from 'classnames';
import {StaticQuery, graphql} from 'gatsby';
import throttle from 'lodash/throttle';

class Nav extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileHidden: true,
            isScrolled: false
        }

        this.throttledScroll = throttle(this.checkScroll, 150);
        
    }

    componentDidMount() {
        window.addEventListener('scroll', this.throttledScroll); 
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.throttledScroll);
    }

    toggleMobileMenu = (e) => {
        this.setState({
            mobileHidden: !this.state.mobileHidden
        })
    }

    checkScroll = (e) => {
        if(window.scrollY > 0) {
            this.setState({
                isScrolled: true
            })
        } else {
            this.setState({
                isScrolled: false
            })
        }
    }

    render() {
        return (
            <nav id="header" className={classnames("fixed", "w-full", "z-30", "top-0", {"text-white": !this.state.isScrolled}, {"bg-white": this.state.isScrolled})}>
                <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
    
                    <div className="pl-4 flex items-center">
                        <Link className={classnames('toggleColour', 'no-underline', 'hover:no-underline', 'font-bold', 'text-2xl', 'lg:text-4xl', {'text-white': !this.state.isScrolled}, {'text-purple-dark': this.state.isScrolled})} to='/'>
                        <svg className={classnames('fill-current', 'lg:w-8', '-mb-1 w-6', {'text-purple-dark': this.state.isScrolled})} version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                        viewBox="0 0 407.686 407.686">
                    <g>
                        <path d="M400.186,92.376h-17.95V75.392c0-3.804-2.848-7.005-6.625-7.449L271.611,55.729c-23.323-2.74-46.669,1.16-67.769,11.282
                            c-21.101-10.122-44.443-14.023-67.769-11.282l-104,12.214c-3.777,0.444-6.625,3.645-6.625,7.449v16.984H7.5
                            c-4.143,0-7.5,3.358-7.5,7.5v245.432c0,4.142,3.357,7.5,7.5,7.5h392.686c4.143,0,7.5-3.358,7.5-7.5V99.876
                            C407.686,95.734,404.328,92.376,400.186,92.376z M269.861,70.627l97.374,11.436v223.057l-95.624-11.23
                            c-20.593-2.419-41.202,0.347-60.269,7.993V80.065C229.613,71.53,249.748,68.265,269.861,70.627z M137.824,70.627
                            c20.112-2.363,40.249,0.903,58.519,9.438v221.82c-14.584-5.848-30.068-8.847-45.758-8.847c-4.823,0-9.668,0.283-14.511,0.851
                            l-95.625,11.23V82.063L137.824,70.627z M392.686,337.808H15V107.376h10.449v206.176c0,2.142,0.915,4.181,2.516,5.604
                            c1.6,1.423,3.733,2.095,5.859,1.845l104-12.214c21.608-2.539,43.248,1.412,62.567,11.424c2.164,1.122,4.738,1.122,6.902,0
                            c19.318-10.012,40.95-13.963,62.567-11.424l103.999,12.214c0.292,0.034,0.584,0.051,0.875,0.051c1.827,0,3.604-0.668,4.984-1.896
                            c1.601-1.423,2.516-3.462,2.516-5.604V107.376h10.45V337.808z"/>
                        <path d="M228.616,112.878c13.442-3.652,27.276-4.679,41.107-3.056l78.085,9.168c0.298,0.035,0.593,0.052,0.885,0.052
                            c3.751,0,6.991-2.809,7.439-6.626c0.482-4.114-2.461-7.84-6.574-8.323l-78.086-9.168c-15.75-1.849-31.491-0.678-46.788,3.479
                            c-3.998,1.086-6.357,5.207-5.271,9.204C220.499,111.605,224.623,113.963,228.616,112.878z"/>
                        <path d="M58.714,119.042c0.292,0,0.588-0.017,0.885-0.052l78.078-9.167c13.813-1.609,27.808-0.558,41.109,3.065
                            c3.995,1.087,8.119-1.268,9.207-5.266c1.089-3.997-1.269-8.119-5.266-9.207c-15.144-4.125-31.062-5.323-46.794-3.49l-78.085,9.168
                            c-4.113,0.483-7.057,4.209-6.574,8.323C51.723,116.233,54.963,119.042,58.714,119.042z"/>
                        <path d="M228.616,147.279c13.442-3.652,27.274-4.679,41.107-3.056l78.085,9.168c0.298,0.035,0.593,0.052,0.885,0.052
                            c3.751,0,6.991-2.809,7.439-6.626c0.482-4.114-2.461-7.84-6.574-8.323l-78.086-9.168c-15.748-1.848-31.49-0.678-46.788,3.479
                            c-3.998,1.086-6.357,5.207-5.271,9.204C220.499,146.005,224.623,148.365,228.616,147.279z"/>
                        <path d="M58.714,153.442c0.292,0,0.588-0.017,0.885-0.052l78.078-9.167c13.804-1.608,27.807-0.558,41.109,3.065
                            c3.995,1.087,8.119-1.268,9.207-5.266c1.089-3.997-1.269-8.119-5.266-9.207c-15.146-4.125-31.069-5.323-46.794-3.49l-78.085,9.168
                            c-4.113,0.483-7.057,4.209-6.574,8.323C51.723,150.633,54.963,153.442,58.714,153.442z"/>
                        <path d="M228.616,181.679c13.442-3.652,27.274-4.679,41.107-3.056l78.085,9.168c0.298,0.035,0.593,0.052,0.885,0.052
                            c3.751,0,6.991-2.809,7.439-6.626c0.482-4.114-2.461-7.84-6.574-8.323l-78.086-9.168c-15.748-1.848-31.49-0.677-46.788,3.479
                            c-3.998,1.086-6.357,5.207-5.271,9.204C220.499,180.406,224.623,182.765,228.616,181.679z"/>
                        <path d="M58.714,187.843c0.292,0,0.588-0.017,0.885-0.052l78.078-9.167c13.803-1.608,27.807-0.558,41.109,3.065
                            c3.995,1.088,8.119-1.268,9.207-5.266c1.089-3.997-1.269-8.119-5.266-9.207c-15.145-4.125-31.068-5.323-46.794-3.491l-78.085,9.168
                            c-4.113,0.483-7.057,4.209-6.574,8.323C51.723,185.034,54.963,187.843,58.714,187.843z"/>
                        <path d="M228.616,216.079c13.44-3.652,27.272-4.68,41.107-3.056l78.085,9.168c0.298,0.035,0.593,0.052,0.885,0.052
                            c3.751,0,6.991-2.809,7.439-6.626c0.482-4.114-2.461-7.84-6.574-8.323l-78.086-9.168c-15.748-1.847-31.489-0.678-46.788,3.479
                            c-3.998,1.086-6.357,5.207-5.271,9.204C220.499,214.806,224.623,217.166,228.616,216.079z"/>
                        <path d="M58.714,222.243c0.292,0,0.588-0.017,0.885-0.052l78.078-9.167c13.8-1.609,27.805-0.559,41.109,3.065
                            c3.995,1.087,8.119-1.268,9.207-5.266c1.089-3.997-1.269-8.119-5.266-9.207c-15.145-4.125-31.072-5.323-46.794-3.49l-78.085,9.168
                            c-4.113,0.483-7.057,4.209-6.574,8.323C51.723,219.434,54.963,222.243,58.714,222.243z"/>
                        <path d="M349.559,241.694l-78.086-9.168c-15.751-1.848-31.492-0.677-46.788,3.479c-3.998,1.086-6.357,5.207-5.271,9.204
                            c1.086,3.998,5.21,6.358,9.203,5.271c13.442-3.651,27.276-4.678,41.107-3.056l78.085,9.168c0.298,0.035,0.593,0.052,0.885,0.052
                            c3.751,0,6.991-2.809,7.439-6.626C356.615,245.904,353.672,242.177,349.559,241.694z"/>
                        <path d="M58.714,256.644c0.292,0,0.588-0.017,0.885-0.052l78.078-9.167c13.804-1.608,27.807-0.558,41.109,3.065
                            c3.995,1.088,8.119-1.268,9.207-5.266c1.089-3.997-1.269-8.119-5.266-9.207c-15.146-4.125-31.069-5.323-46.794-3.49l-78.085,9.168
                            c-4.113,0.483-7.057,4.209-6.574,8.323C51.723,253.835,54.963,256.644,58.714,256.644z"/>
                    </g>
                    </svg> {this.props.name}
                </Link>
                    </div>
    
                    <div className="block lg:hidden pr-4">
                        <button onClick={this.toggleMobileMenu} id="nav-toggle" className="flex items-center px-3 py-2 border rounded text-gray-500 border-gray-600 hover:text-gray-800 hover:border-teal-500 appearance-none focus:outline-none">
                            <svg className="fill-current h-3 w-3" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><title>Menu</title><path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" /></svg>
                        </button>
                    </div>
    
                    <div className={classnames("w-full", "flex-grow", "lg:flex", "lg:items-center", "lg:w-auto", "lg:block", "mt-2", "lg:mt-0", "bg-white", "lg:bg-transparent", "text-black", "p-4", "lg:p-0", "z-20", "lg:pb-0", "pb-12", {hidden: this.state.mobileHidden})} id="nav-content">
                        <ul className="list-reset lg:flex justify-end flex-1 items-center lg:mb-0 mb-12">
                            <li className="mr-3">
                                <Link to="/about" className={classnames("insline-block", "py-2", "px-4", 'text-black',"no-underline", {"font-bold": this.props.active && this.props.active === "about", 'text-white' : !this.state.isScrolled})}>À propos</Link>
                            </li>
                        </ul>
                        <Link to='/' className={
                            classnames("mx-auto","lg:mx-0", "hover:underline", "bg-white", "text-gray-800", "font-bold", "rounded-full", "mt-4", "lg:mt-0", "py-4", "px-8", "shadow", "opacity-75" , "no-underline", "hover:no-underline",{gradient: !this.state.mobileHidden || this.state.isScrolled}, {"text-white": !this.state.mobileHidden || this.state.isScrolled})
                        }>Cours</Link>
                    </div>
                </div>
    
                <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
            </nav>
        )
    }
}

const NavHandler = ({active}) => {
    return (
        <StaticQuery
                query={graphql`
                    query {
                        site {
                            siteMetadata {
                                title
                            }
                        }
                    }
                `}
                render={data => <Nav active={active} name={data.site.siteMetadata.title}/>}
            />
    )
}


export default NavHandler;